import React from "react";
import PropTypes from "prop-types";

import ChargingStationCard from "../ChargingStationCard/ChargingStationCard";
import ShowHideButton from "../../../../components/ShowHideButton/ShowHideButton"

import "./ChargingStationsList.scss";

const ChargingStationsList = ({ chargingStations }) => {  
  return (
    <div className="ChargingStationsList">
      <ShowHideButton buttonText="Full List of Stations">
        <div className="charging-station-cards-container">
          <div className="row">
            <div className="col-sm-6 offset-sm-3">
              {chargingStations.map((station, i) => {
                return (
                  <ChargingStationCard
                    key={station.id}
                    chargingStation={station}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </ShowHideButton>
    </div>
  );
};

ChargingStationsList.propTypes = {
  chargingStations: PropTypes.array
};

ChargingStationsList.defaultProps = {
  chargingStations: []
};

export default ChargingStationsList;
