import VehicleCost from "./CostOfOwnership/calcs/VehicleCost";
import capitalizedInPurchase from "../incentives/capitalization/capitalizedInPurchase";
import capitalizedInLease from "../incentives/capitalization/capitalizedInLease";
import nonCapitalizedInLease from "../incentives/capitalization/nonCapitalizedInLease";
import verifyIncentiveType from "../incentives/capitalization/verifyIncentiveType";

const getPaymentDetails = (
  vehicle,
  monthsOfOwnership,
  interestRateAsBasisPoints,
  milesDrivenAnnually,
  salesTax
) => {
  const filteredIncentives = verifyIncentiveType(vehicle.incentives);

  const incentivesForPurchase = capitalizedInPurchase(filteredIncentives);
  const incentivesForLease =
    capitalizedInLease(filteredIncentives) +
    nonCapitalizedInLease(filteredIncentives);

  const afterIncentives = VehicleCost.afterIncentives(vehicle);
  const loanDownPayment = VehicleCost.downPayment(vehicle, salesTax);
  const loanAmountPerMonth = VehicleCost.monthlyLoanPayment(
    vehicle,
    monthsOfOwnership,
    interestRateAsBasisPoints,
    salesTax
  );
  const leaseFirstPayment = VehicleCost.firstLeasePayment(
    vehicle,
    milesDrivenAnnually,
    monthsOfOwnership,
    interestRateAsBasisPoints,
    salesTax
  );
  const leaseAmountPerMonth = VehicleCost.monthlyLeasePayment(
    vehicle,
    milesDrivenAnnually,
    monthsOfOwnership,
    interestRateAsBasisPoints,
    salesTax
  );

  const yearsOfOwnership = monthsOfOwnership / 12;

  return {
    afterIncentives: afterIncentives,
    loanAmountPerMonth: loanAmountPerMonth,
    downPayment: loanDownPayment,
    leaseAmountPerMonth: leaseAmountPerMonth,
    leaseFirstPayment: leaseFirstPayment,
    msrp: vehicle.msrp,
    interestRateAsBasisPoints: interestRateAsBasisPoints,
    yearsOfOwnership: yearsOfOwnership,
    totalIncentivesForPurchase: incentivesForPurchase,
    totalIncentivesForLease: incentivesForLease
  };
};

export default getPaymentDetails;
