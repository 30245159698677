
import React, { useState, useContext, useEffect, useCallback } from "react";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "chartjs-plugin-datalabels";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import filterEVs from "../../../functions/vehicle/Filter/filterEVs";
import { FormatAsThousands } from "../../../utils/Helpers/Format"
import getPaymentDetails from "../../../functions/vehicle/getPaymentDetails"

const caret = (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.281585 0.251051C0.65703 -0.0836838 1.26575 -0.0836838 1.64119 0.251051L7.40953 5.39391C7.78498 5.72864 7.78498 6.27136 7.40953 6.60609L1.64119 11.7489C1.26575 12.0837 0.65703 12.0837 0.281585 11.7489C-0.0938615 11.4142 -0.0938615 10.8715 0.281585 10.5368L5.37012 6L0.281585 1.46323C-0.0938615 1.1285 -0.0938615 0.585786 0.281585 0.251051Z"
      fill="#07679B"
    />
  </svg>
);

const CostVsRangeList = ({ electricVehicles }) => {

    const userPrefs = useContext(UserPrefsContext);
    const [selectedOrder, setSelectedOrder] = useState('total')
    const [filteredAndSortedElectricVehicles, setFilteredAndSortedElectricVehicles] = useState([]);
    const [cards, setCards] = useState([]);

    const milesDrivenAnnually = userPrefs.get("milesDrivenAnnually");

    const renderEVCard = useCallback(() => {
      if (selectedOrder === "total") {
        filteredAndSortedElectricVehicles.sort((a, b) => {
          let costA = getPaymentDetails(
            a,
            userPrefs.get("monthsOfOwnership"),
            userPrefs.get("interestRateAsBasisPoints"),
            milesDrivenAnnually,
            userPrefs.get("salesTax")
          );
          let costB = getPaymentDetails(
            b,
            userPrefs.get("monthsOfOwnership"),
            userPrefs.get("interestRateAsBasisPoints"),
            milesDrivenAnnually,
            userPrefs.get("salesTax")
          );

          let valueA = a.total_range / costA.afterIncentives;
          let valueB = b.total_range / costB.afterIncentives;

          return valueA > valueB ? -1 : 1;
        });
      } else {
        filteredAndSortedElectricVehicles.sort((a, b) => {
          let costA = getPaymentDetails(
            a,
            userPrefs.get("monthsOfOwnership"),
            userPrefs.get("interestRateAsBasisPoints"),
            milesDrivenAnnually,
            userPrefs.get("salesTax")
          );
          let costB = getPaymentDetails(
            b,
            userPrefs.get("monthsOfOwnership"),
            userPrefs.get("interestRateAsBasisPoints"),
            milesDrivenAnnually,
            userPrefs.get("salesTax")
          );

          let valueA = a.electric_range / costA.afterIncentives;
          let valueB = b.electric_range / costB.afterIncentives;

          return valueA > valueB ? -1 : 1;
        });
      }

      let arr = [];

      filteredAndSortedElectricVehicles.map((ev) => {
        let paymentDetails = getPaymentDetails(
          ev,
          userPrefs.get("monthsOfOwnership"),
          userPrefs.get("interestRateAsBasisPoints"),
          milesDrivenAnnually,
          userPrefs.get("salesTax")
        );

        return arr.push(
          <Link
            to={`/vehicles/${ev.handle}`}
            style={{ textDecoration: "none" }}
            key={ev.handle}
          >
            <div className="cvr-evcard">
              <div className="row mb-2">
                <img src={ev.images[0].url_full} alt="Car card thumbnail" />
                <p className="link-text">{ev.display_name}</p>
                <p className="link-text ml-auto">{caret}</p>
              </div>
              <div className="row gray-block justify-content-between">
                <p className="link-text">MSRP After Incentives</p>
                <p className="link-text ml-auto">
                  ${FormatAsThousands(paymentDetails.afterIncentives)}
                </p>
              </div>
              <div className="row justify-content-between">
                <p className="link-text">Total Range (mi)</p>
                <p className="link-text ml-auto">
                  {FormatAsThousands(ev.total_range)}
                </p>
              </div>
              <div className="row gray-block justify-content-between">
                <p className="link-text">Electric Range (mi)</p>
                <p className="link-text ml-auto">
                  {FormatAsThousands(ev.electric_range)}
                </p>
              </div>
            </div>
          </Link>
        );
      });

      setCards(arr);
    }, [selectedOrder, filteredAndSortedElectricVehicles, userPrefs, milesDrivenAnnually]);

    useEffect(() => {
      if (!filteredAndSortedElectricVehicles.length) return;
      renderEVCard();
    }, [selectedOrder, filteredAndSortedElectricVehicles, renderEVCard]);

    useEffect(() => {
      if (!electricVehicles) return;

      const newData = filterEVs(electricVehicles, {
        vehicleFormFactorFilters: userPrefs.get("vehicleFormFactorFilters"),
        vehicleFuelTypeFilters: userPrefs.get("vehicleFuelTypeFilters"),
        vehicleMakeFilter: userPrefs.get("vehicleMakeFilter"),
        vehicleAgeFilter: userPrefs.get("vehicleAgeFilter"),
      });

      setFilteredAndSortedElectricVehicles(newData);
    }, [electricVehicles, userPrefs]);      

    if (!electricVehicles) return <LoadingSpinner />;

    if (!filteredAndSortedElectricVehicles.length) {
      return (
        <div className="text-center">
          <p className="h4">No vehicles found</p>
        </div>
      );
    } 

    return (
      <div className="cvr-list-container">
        <div className="row d-flex align-items-center d-md-none mobileText mx-0 my-4">
          <label className="input-label">Sort By</label>
          <select
            className="form-control select-dte cvr-select ml-3"
            value={selectedOrder}
            onChange={(e) => setSelectedOrder(e.target.value)}
          >
            <option value="total">Highest Total Range, Lowest Cost</option>
            <option value="electric">
              Highest Electric Range, Lowest Cost
            </option>
          </select>
        </div>

        <div className="cost-vs-range-list">{cards}</div>
      </div>
    );
}

export default CostVsRangeList

CostVsRangeList.propTypes = {
    electricVehicles: PropTypes.array
}
