import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import useSelectedStation from "../../../hooks/useSelectedStation";
import useChargingStationsNearRoute from '../../../hooks/useChargingStationsNearRoute';

//import GaTracker from "../../utils/GaTracker/GaTracker";
import filterWithinBounds from '../../../utils/Helpers/filterWithinBounds';
import setupGoogleMapsAutocomplete from '../../../utils/setupGoogleMapsAutocomplete';

import BaseGoogleMapReact from "../BaseGoogleMapReact/BaseGoogleMapReact";
import MapControlPanel from "../MapControlPanel/MapControlPanel";
import ChargingStationsList from '../ChargingStationsList/ChargingStationsList';

import "./TravelRouteMap.scss";
import { FormattedMessage } from "react-intl";

const overviewPathAsLinestring = overviewPath => {
  const coordsAsText = overviewPath
    .map(coords => `${coords.lng()} ${coords.lat()}`)
    .join(", ");
  return `LINESTRING(${coordsAsText})`;
};

const TravelRouteMap = ({
  chargingStationsFilterFn,
  isVisible = true,
}) => {
  const [startAddress, setStartAddress] = useState(
    process.env.REACT_APP_PAGES_MAP_ROUTE_START_ADDRESS || "San Francisco, CA, USA"
  );
  const [destinationAddress, setDestinationAddress] = useState(
    process.env.REACT_APP_PAGES_MAP_ROUTE_END_ADDRESS || "Oakland, CA, USA"
  );
  const [isFetchingRoute, setIsFetchingRoute] = useState(false);
  const [routeError, setRouteError] = useState();
  const [routeDistanceInMeters, setRouteDistanceInMeters] = useState();
  const [bounds, setBounds] = useState({
    nw: undefined,
    ne: undefined,
    sw: undefined,
    se: undefined
  });

  const directionsServiceRef = useRef(null);
  const directionsRendererRef = useRef(null);
  const autocompleteStartLocationRef = useRef(null);
  const autocompleteEndLocationRef = useRef(null);

  const {
    chargingStations,
    fetchChargingStations,
    error: chargingStationsError
  } = useChargingStationsNearRoute();

  const [selectedStation, selectStation, deselectStations] = useSelectedStation(
    chargingStations
  );

  const onCompleteStartLocation = () => {
    const address = autocompleteStartLocationRef.current.getPlace()
      .formatted_address;
    setStartAddress(address);
  };

  const onCompleteEndLocation = () => {
    const address = autocompleteEndLocationRef.current.getPlace()
      .formatted_address;
    setDestinationAddress(address);
  };

  const saveMapRefs = ({ map }) => {
    /* eslint-disable no-undef */
    directionsServiceRef.current = new google.maps.DirectionsService();
    directionsRendererRef.current = new google.maps.DirectionsRenderer();
    /* eslint-enable no-undef */

    setupGoogleMapsAutocomplete(
      autocompleteStartLocationRef,
      "travel-route-map-start-location",
      onCompleteStartLocation
    );
    setupGoogleMapsAutocomplete(
      autocompleteEndLocationRef,
      "travel-route-map-end-location",
      onCompleteEndLocation
    );

    directionsRendererRef.current.setMap(map);
    fetchRoute({ isTrackingEvent: false });
  };

  const isSubmittingAllowed = () =>
    !isFetchingRoute && startAddress && destinationAddress;

  const fetchRoute = ({ isTrackingEvent = true }) => {
    setIsFetchingRoute(true);
    setRouteError(undefined);
    setRouteDistanceInMeters(undefined);
    //if (isTrackingEvent) {
      //GaTracker.trackEvent({
        //category: "Maps",
        //action: "Searched Travel Route"
      //});
    //}

    directionsServiceRef.current.route(
      {
        origin: startAddress,
        destination: destinationAddress,
        travelMode: "DRIVING"
      },
      (response, status) => {
        if (status === "OK") {
          directionsRendererRef.current.setDirections(response);

          if (response.routes && response.routes.length > 0) {
            // See https://developer.nrel.gov/docs/transportation/alt-fuel-stations-v1/nearby-route/
            const linestring = overviewPathAsLinestring(
              response.routes[0].overview_path
            );

            fetchChargingStations({ route: linestring });
            const distanceInMeters = response.routes[0].legs.reduce(
              (totalDistance, leg) => totalDistance + leg.distance.value,
              0
            );
            setRouteDistanceInMeters(distanceInMeters);
          }
        } else {
          setRouteError("Error fetching route");
        }
        setIsFetchingRoute(false);
      }
    );
  };

  const handleKeyPress = event => {
    if (event.key === "Enter" && isSubmittingAllowed()) {
      fetchRoute({});
    }
  };

  const handleMapChange = ({ bounds }) => setBounds(bounds);

  const visibleChargingStations = filterWithinBounds(chargingStations, bounds);
  const filteredChargingStations = chargingStationsFilterFn
    ? visibleChargingStations.filter(chargingStationsFilterFn)
    : visibleChargingStations;

  return (
    <div className="row TravelRouteMap">
      <div className="col-sm-3">
        <MapControlPanel
          selectedStation={selectedStation}
          chargingStations={filteredChargingStations}
        >
          <div className="form-group">
            <label htmlFor="travel-route-map-start-location">
              Start Location
            </label>
            <input
              id="travel-route-map-start-location"
              type="text"
              className="form-control"
              onChange={e => setStartAddress(e.target.value)}
              value={startAddress}
              placeholder="Start Location"
              disabled={isFetchingRoute}
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className="form-group">
            <label htmlFor="travel-route-map-end-location">End Location</label>
            <input
              id="travel-route-map-end-location"
              type="text"
              className="form-control"
              onChange={e => setDestinationAddress(e.target.value)}
              value={destinationAddress}
              placeholder="End Location"
              disabled={isFetchingRoute}
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className="form-group">
            <button
              className="btn-ae btn-default mb-2 btn-block"
              type="button"
              onClick={fetchRoute}
              disabled={!isSubmittingAllowed()}
            >
              Map Route
            </button>
          </div>
          {chargingStationsError && (
            <p className="text-danger">
              There was an error fetching charging stations. Please try again.
            </p>
          )}
          {routeError && (
            <p className="text-danger">
              We could not find that route. Please check the addresses and try
              again.
            </p>
          )}
        </MapControlPanel>
      </div>
      <div className="col-sm-9">
        <div className="map-container">
          {routeDistanceInMeters && (
            <div className="route-distance-legend">
              This route is {Math.ceil(routeDistanceInMeters / 1609)} miles
            </div>
          )}
          {isVisible && (
            <BaseGoogleMapReact
              onGoogleApiLoaded={saveMapRefs}
              onHoverOnStation={selectStation}
              onClickMarker={deselectStations}
              chargingStations={filteredChargingStations}
              selectedStation={selectedStation}
              onChange={handleMapChange}
            />
          )}
          <p className="legal-disclaimer">
            <FormattedMessage 
              id="map.legalDisclaimer"
              defaultMessage="Data courtesy of the Alternative Fuel Data Center. DTE cannot change the data shown on the map and it may not reflect the latest availability of charging stations. If you are aware of a charging location not listed, you can submit it at https://afdc.energy.gov/stations/#/station/new"
              description="Data courtesy of the Alternative Fuel Data Center"
              values= {{
                link: <a href={'https://afdc.energy.gov/stations/#/station/new'} target="_blank" rel="noopener noreferrer">https://afdc.energy.gov/stations/#/station/new</a>
              }}
            />
          </p>
        </div>
      </div>

      <div className="col-sm-12">
        <ChargingStationsList chargingStations={filteredChargingStations} />
      </div>
    </div>
  );
};

TravelRouteMap.propTypes = {
  chargingStationsFilterFn: PropTypes.func,
  isVisible: PropTypes.bool,
};

export default TravelRouteMap;
