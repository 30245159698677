import calcTotalCostOfOwnership from "../../functions/vehicle/CostOfOwnership/calcTotalCostOfOwnership";
// IMPORT ASSUMPTIONS HERE AND TOGGLE HERE
import {
  SALES_TAX,
  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH,
} from "../../client_customizations/data/assumptions/ASSUMPTIONS";

const getCarCostOfOwnership = (vehicle, userPrefs, insuranceData, maintenanceData, forceUserPrefsPresets) => {
  const get = forceUserPrefsPresets ? userPrefs.getPreset : userPrefs.get;
  return calcTotalCostOfOwnership(
    vehicle,
    get("purchaseMethod"),
    get("monthsOfOwnership"),
    get("milesDrivenAnnually"),
    get("interestRateAsBasisPoints"),
    get("electricMilesPortionForPhev"),
    get("gasolinePriceInCentsPerGal"),
    get("includeResaleValue"),
    process.env.REACT_APP_SLIDE_SALES_TAX
      ? get("salesTax")
      : SALES_TAX.value,
    process.env.REACT_APP_SLIDE_COST_PUBLIC_CHARGING
      ? get("costPublicCharging")
      : 0,
    process.env.REACT_APP_SLIDE_PORTION_PUBLIC_CHARGING
      ? get("publicChargingPercentage")
      : 0,
    process.env.REACT_APP_DYNAMIC_ELECTRIC_RATE
      ? get("electricityRate")
      : ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.value,
    process.env.REACT_APP_DYNAMIC_INSURANCE_COST
      ? get("municipality")
      : null,
      insuranceData,
      maintenanceData
  );
};

export default getCarCostOfOwnership;