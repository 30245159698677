import React from "react";
import PropTypes from "prop-types";
import "./ToggleSwitch.scss";

const ToggleSwitch = ({id, text, onChange, filterKey, image, fuelTypeFilterData}) => {
    
    return (
        <div className="row">
            <div className="col-2 toggle-image">
                <img src={image} alt={filterKey}/>
            </div>
        <div className="toggle-switch col-10">
            <input
                type="checkbox"
                className="toggle-switch-checkbox"
                id={id}
                checked={fuelTypeFilterData[filterKey]}                
                onChange={()=>onChange(filterKey)}
            />
            {id ? (
            <label className="toggle-switch-label" htmlFor={id}>
                <span
                className="toggle-switch-inner"
                data-yes={text}
                data-no={text}
                />
                <span
                className="toggle-switch-switch"
                />
            </label>
            ) : null}
        </div>
      </div>
    )
};

export default ToggleSwitch;

ToggleSwitch.propTypes = {
    id: PropTypes.string,
    text: PropTypes.string,
    onChange:PropTypes.func,
    filterKey: PropTypes.string,
    image: PropTypes.string
};
