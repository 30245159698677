import React from "react";
import PropTypes from "prop-types";
import "./EVReviews.scss";

import VideoReview from "./../VideoReview/VideoReview";

const EVReviews = ({ reviews }) => {
  if (!reviews) return null;

  const approvedReviewers = new Set(["Consumer Reports", "KBB (Kelley Blue Book)", "Motor Trend", "Car And Driver", "CNET"])

  reviews = reviews.filter(review => {
    return approvedReviewers.has(review.reviewer)
  })

  if (reviews.length === 0) return null

  return (
    <section className="container section-divider-above">
      <div className="EVReviews">
        <div className="row">
          <div className="col-sm-12">
            <h1>Reviews from around the web</h1>
          </div>
        </div>
        <div className="row">
          {reviews.map((review, index) => {
            return <VideoReview link={review.video_url} title={review.reviewer} key={index} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default EVReviews;

EVReviews.propTypes = {
  reviews: PropTypes.array
};
