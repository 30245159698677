import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from 'reactstrap';

import './Subheader.scss';
import { useIntl } from "react-intl";

const Subheaeder = ({ page }) => {
  const intl = useIntl();
  const [collapse, setCollapse] = useState(false);

  return (
    <div className="Subheader">
      <div className="container d-none d-lg-block" style={{ padding: 0 }}>
        <Navbar expand="lg">
          <NavbarToggler onClick={() => setCollapse(!collapse)} />
          <Collapse isOpen={collapse} navbar>
            <Nav className="nav-fill w-100" navbar>
              <NavItem>
                <a
                  href="https://www.dteenergy.com/us/en/residential/service-request/pev/plug-in-electric-vehicles-pev.html/?utm_campaign=ev_homepage_cta&utm_source=zappy&utm_medium=affiliate_ev_site&utm_content="
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  EV Resources
                </a>
              </NavItem>
              <NavItem>
                <a
                  href="https://www.dteenergy.com/us/en/residential/service-request/pev/home-ev-charger-rebate.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Home EV Charger Rebate
                </a>
              </NavItem>
              <NavItem>
                <a href="/vehicles">EV Showroom</a>
              </NavItem>
              <NavItem>
                <a href="/comparison-graph">
                  {intl.formatMessage({
                    id: "vehicle.costVsRange",
                    defaultMessage: "Cost vs. Range",
                  })}
                </a>
              </NavItem>
              <NavItem>
                <a href="/compare-vehicles">Compare Vehicles</a>
              </NavItem>
              <NavItem>
                <a href="/charging-stations">Charging Map</a>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Subheaeder;

Subheaeder.propTypes = {
  page: PropTypes.string,
};
