import React, { useContext } from "react";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext"

import EVFuelTypeFilter from "./EVFuelTypeFilter";
import SelectVehicleMakeFilter from "../../../components/InputComponents/SelectVehicleMakeFilter/SelectVehicleMakeFilter";
import SelectVehicleAgeFilter from "../../../components/InputComponents/SelectVehicleAgeFilter/SelectVehicleAgeFilter";

import {FormattedMessage, useIntl} from 'react-intl';

const EVFilterControls = ({vehicles, usedEvs, close}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);

  const formFactorFilterData = userPrefs.get("vehicleFormFactorFilters");

  const vehicleTypes = {
    Sedan: intl.formatMessage ? intl.formatMessage({ id: "vehicle.type.sedan", defaultMessage: "Sedan"}) : "Sedan",
    Hatchback: intl.formatMessage ? intl.formatMessage({ id: "vehicle.type.hatchback", defaultMessage: "Hatchback"}) : "Hatchback",
    Coupe: intl.formatMessage ? intl.formatMessage({ id: "vehicle.type.coupe", defaultMessage: "Coupe"}) : "Coupe",
    Crossover: intl.formatMessage ? intl.formatMessage({ id: "vehicle.type.crossover", defaultMessage: "Crossover"}) : "Crossover",
    Minivan: intl.formatMessage ? intl.formatMessage({ id: "vehicle.type.minivan", defaultMessage: "Minivan"}) : "Minivan",
    SUV: intl.formatMessage ? intl.formatMessage({ id: "vehicle.type.suv", defaultMessage: "SUV"}) : "SUV",
    Wagon: intl.formatMessage ? intl.formatMessage({ id: "vehicle.type.wagon", defaultMessage: "Wagon"}) : "Wagon",
    Truck: intl.formatMessage ? intl.formatMessage({ id: "vehicle.type.truck", defaultMessage: "Truck"}) : "Truck"
  }


  const updateFormFactorFilter = key => {
    let newFilters = Object.assign({}, formFactorFilterData);
    newFilters[key] = !newFilters[key];

    userPrefs.set({
      vehicleFormFactorFilters: newFilters
    });
  };

  const renderedFormFactorFilters = Object.keys(formFactorFilterData).map(
    (filterKey, i) => {
      const filterName =
        filterKey === "suv"
          ? "SUV"
          : `${filterKey.charAt(0).toUpperCase()}${filterKey.substr(1)}`;
      return (
        <button
          className={formFactorFilterData[filterKey] ? "btn h40" : "btn active h40"}
          key={i}
          type="button"
          aria-pressed={!!formFactorFilterData[filterKey]}
          onClick={() => updateFormFactorFilter(filterKey)}
        >
          {vehicleTypes[filterName]}
        </button>
      );
    }
  );

  const clearFilters = () => {
    userPrefs.set({
      vehicleFormFactorFilters: null,
      vehicleFuelTypeFilters: null,
      vehicleMakeFilter: null,
    });
  }

  
  return (
    <div className="input-well">
      <form>
        <div className="form-group mb-2">
          <span className="label-style">FUEL TYPE</span>
          <div className="toggle container">
            <EVFuelTypeFilter />
          </div>
        </div>

        <div className="form-group">
          <SelectVehicleMakeFilter vehicles={vehicles} />
        </div>

        <div className="form-group">
          <span className="label-style">
            <FormattedMessage
              id="evfilter.type"
              defaultMessage="Type"
              description="Type"
            />
          </span>
          <div className="btn-grid-container">{renderedFormFactorFilters}</div>
        </div>

        {usedEvs ? (
          <div className="form-group">
            <SelectVehicleAgeFilter />
          </div>
        ) : null}

        <div className="apply-btn-col mt-4 mb-0 d-flex d-md-none mobileText">
          <button onClick={close} className="secondary-btn mb-2">
            Apply Filters
          </button>

          <p className="link-text" onClick={clearFilters}>
            clear all
          </p>
        </div>
      </form>
    </div>
  );
};

export default EVFilterControls;
