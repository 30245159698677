const RESIDUALVALUES = 
    {
        "Ford_Mustang_Mach_E_Premium_BEV_2021" : .45,
        "Ford_Mustang_Mach_E_Select_BEV_2021" : .45, 
        "Ford_Mustang_Mach_E_First_Edition_BEV_2021" : .45, 
        "Ford_Mustang_Mach_E_California_Rt_1_BEV_2021" : .45,
        "Ford_Mustang_Mach_E_GT_BEV_2021": .339,
    };
export {
    RESIDUALVALUES
};