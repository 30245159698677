import calcTotalCostOfOwnership from "./calcTotalCostOfOwnership"

const getCarCostOfOwnership = (vehicle, userPrefs, insuranceData) => {
    return calcTotalCostOfOwnership(
        vehicle,
        userPrefs.get("purchaseMethod"),
        userPrefs.get("monthsOfOwnership"),
        userPrefs.get("milesDrivenAnnually"),
        userPrefs.get("interestRateAsBasisPoints"),
        userPrefs.get("electricMilesPortionForPhev"),
        userPrefs.get("gasolinePriceInCentsPerGal"),
        userPrefs.get("includeResaleValue"),
        userPrefs.get("salesTax"),
        insuranceData
      )
}

export default getCarCostOfOwnership