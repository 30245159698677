import React, {useState} from "react";
import PropTypes from "prop-types";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner"
import useDeepCompareEffect from "../../hooks/useDeepCompareEffect";

import "./VehicleImage.scss";
 


let VehicleImage = ({ image, size, alt }) => {

  const [isLoaded, setIsLoaded] = useState(false)

  useDeepCompareEffect(
    () => {
      setIsLoaded(false);
    },
    [image]
  );

  if (!image) return null;
  const src = size === "full" ? image.url_full : image.url_thumbnail;
  alt = alt || image["legal_info"];

  const imgClasses = `img-fluid${isLoaded ? "" : " img-loading"}`;
  
  return (
    <span className="VehicleImage">
      {!isLoaded && (
        <div className="loading-overlay">
          <LoadingSpinner />
        </div>
      )}
      <img
        src={src}
        alt={alt}
        className={imgClasses}
        onLoad={() => setIsLoaded(true)}
      />
    </span>
  );
};

export default VehicleImage;

VehicleImage.propTypes = {
  image: PropTypes.object,
  alt: PropTypes.string,
  size: PropTypes.string
};
