import React from "react";
import PropTypes from "prop-types";

// import sortIncentives from "../../functions/incentives/Sort/sortIncentives";
// import IncentivePreferences from "./../IncentivesPreferences/IncentivePreferences";
import IncentiveCatalog from "../IncentiveCatalog/IncentiveCatalog";
import { FormatCarName } from "./../../utils/Helpers/Format";
import ToolTip from "../shared/ToolTip/ToolTip";

const EVIncentives = ({ car, incentives, usedEv }) => {
  if (!incentives) return null;

  incentives = usedEv ? incentives.filter(incentive => incentive.applicable_to_used_vehicles) : incentives
  const tooltipText = "Estimated maximum incentive amount calculated without knowledge or user household income, household size, and Level 2 charger purchase status. These factors all affect maximum eligibility and may decrease maximum incentive estimate.";

  
  return (

    <section className="container section-divider section-divider-above">
      <div className="EVIncentives" id="EVIncentives">
        <div className="row">
          <div className="col-12">
            <p className="h1 mb-3 text-left">
            Tax credits and rebates that are potentially available for the{" "}
              {FormatCarName(car)} <ToolTip id={"incentives_title_tooltip"} message={tooltipText} />
            </p>
            <IncentiveCatalog
              incentives={incentives}
            />
            <br />
            {/* <div className="text-center" style={{ marginBottom: "31px" }}>
              <IncentivePreferences
                titleText="PERSONALIZE INCENTIVES"
                btnText={intl.formatMessage ? intl.formatMessage({ id: "personalizeIncentives", defaultMessage: "PERSONALIZE INCENTIVES"}) : "PERSONALIZE INCENTIVES"}
              />
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EVIncentives;

EVIncentives.propTypes = {
  car: PropTypes.object,
  incentives: PropTypes.array
};
