import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "../../../pages/EVs/EVs.scss";
import EVFilterControls from "./../EVFilterControls/EVFilterControls";
import EVFuelTypeFilter from "./../EVFilterControls/EVFuelTypeFilter";
import EVFormFactorFilter from "./../EVFilterControls/EVFormFactorFilter";
import totalRange from "../../assets/images/Total_Range.svg"
import electricRange from "../../assets/images/Electric_Range.svg"
import totalRangeActive from "../../assets/images/Total_Range_Active.svg"
import electricRangeActive from "../../assets/images/Electric_Range_Active.svg"
import CostVsRangeGraph from "../CostVsRangeGraph/CostVsRangeGraph";
import CostVsRangeList from "../CostVsRangeList/CostVsRangeList";

const EVsGraph = ({ electricVehicles, ip, uuid }) => {

  useEffect(() => {
    document.title = "Range vs Cost";
  });

  const time = new Date().toLocaleString();

  const [toggleRange, setToggleRange] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleClick = (e) => {
    if (e.target.dataset.toggle === "Total Range") {
      setToggleRange(true)
    } else {
      setToggleRange(false)
    }
  }

  return (
    <section className="container">
      <div className="row mb-1 mb-md-3 d-none d-md-flex">
        <div className="col-sm-6">
          <h1>Cost vs. Range</h1>
        </div>
        <div className="col-sm-6">
          <EVFuelTypeFilter />
        </div>
      </div>

      <div className="row mb-3 md-mb-0 d-none d-md-block">
        <div className="col-sm-8">
          <p className="lead pt-0 pt-md-4">
            Hover over individual dots to explore various EVs. Click to learn
            more, including total cost of ownership and how much you can save
            compared to a similar gas vehicle.
          </p>
        </div>
      </div>

      <div className="row d-none d-lg-block">
        <EVFormFactorFilter vehicles={electricVehicles} />
      </div>

      <div className="cost-vs-range-graph">
        <div className="row">
          <div
            id="electric-vehicles-catalog"
            aria-live="polite"
            aria-atomic="true"
            role="region"
            style={{ position: "absolute", top: "-9999px" }}
          >
            <span>Results have been updated as of {time}.</span>
          </div>

          <div
            className="col-sm-12 d-none d-lg-block"
            style={{ paddingLeft: 10 }}
          >
            <div className="row">
              <div className="col">
                <div className="pull-right"></div>
              </div>
            </div>
            <CostVsRangeGraph
              electricVehicles={electricVehicles}
              toggleRange={toggleRange}
            />
          </div>
        </div>
      </div>

      <div
        className="row rangeToggle d-none d-lg-flex"
        style={{ paddingTop: "20px", paddingBottom: "20px" }}
      >
        <button
          type="button"
          className={toggleRange ? "toggle-btn-active" : "toggle-btn"}
          data-toggle="Total Range"
          onClick={handleClick}
        >
          <img
            className={
              toggleRange
                ? "evs-toggle-image toggle-image-active"
                : "evs-toggle-image toggle-image-passive"
            }
            data-toggle="Total Range"
            alt="Lightning Bolt and Gasoline Icon"
            src={toggleRange ? totalRangeActive : totalRange}
          ></img>
          <img
            className={
              toggleRange
                ? "evs-toggle-image toggle-image-passive"
                : "evs-toggle-image toggle-image-hover"
            }
            data-toggle="Total Range"
            alt="Lightning Bolt and Gasoline Icon"
            src={totalRangeActive}
          ></img>
          Total Range (Miles)
        </button>

        <button
          type="button"
          className={toggleRange ? "toggle-btn" : "toggle-btn-active"}
          data-toggle="Electric Range"
          onClick={handleClick}
        >
          <img
            className={
              toggleRange
                ? "evs-toggle-image toggle-image-passive"
                : "evs-toggle-image toggle-image-active"
            }
            data-toggle="Electric Range"
            alt="Lightning Bolt Icon"
            src={toggleRange ? electricRange : electricRangeActive}
          ></img>
          <img
            className={
              toggleRange
                ? "evs-toggle-image toggle-image-hover"
                : "evs-toggle-image toggle-image-passive"
            }
            data-toggle="Electric Range"
            alt="Lightning Bolt Icon"
            src={electricRangeActive}
          ></img>
          Electric Range (Miles)
        </button>
      </div>

      <div className="row justify-content-center d-none d-lg-block">
        <p className="cost-vs-range-disclaimer">
          *Vehicles displayed may not reflect actual availability. DTE does not
          endorse or recommend any specific vehicle or car manufacturer.
        </p>
      </div>

      <div className="row mb-1 mb-md-3 d-block d-md-none">
        <div className="col-sm-6">
          <h1>Electric Vehicles</h1>
        </div>
      </div>

      <div className="row mb-3 md-mb-0 d-block d-md-none">
        <div className="col-sm-8">
          <p className="lead pt-0 pt-md-4">
            By default, the listing below displays EVs by how far you can travel
            on a single charge vs. the cost of the vehicle. Please visit this
            page on a desktop device for a graphical representation of this
            data.
          </p>
        </div>
      </div>

      <div className="row justify-content-center d-flex d-md-none mobileText">
        <button onClick={() => setIsModalOpen(true)} className="secondary-btn">
          Filters
        </button>
      </div>

      <div className="d-flex d-md-none">
        <CostVsRangeList
          electricVehicles={electricVehicles}
          toggleRange={toggleRange}
        />
      </div>

      <div
        className={`${
          isModalOpen ? "d-flex" : "d-none"
        } col d-md-none cvr-modal`}
      >
        <div className="row p-0 col-sm-12 d-flex justify-content-between align-items-center mb-3">
          <h5>Filters</h5>
          <button
            className="modal-close-btn"
            onClick={() => setIsModalOpen(false)}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 1.4L12.6 0L7 5.6L1.4 0L0 1.4L5.6 7L0 12.6L1.4 14L7 8.4L12.6 14L14 12.6L8.4 7L14 1.4Z"
                fill="#2A2A2A"
              />
            </svg>
          </button>
        </div>

        <EVFilterControls
          close={() => setIsModalOpen(false)}
          vehicles={electricVehicles}
        />
      </div>
    </section>
  );
};

export default EVsGraph;

EVsGraph.propTypes = {
  electricVehicles: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
