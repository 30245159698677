import React from "react";
import PropTypes from "prop-types";
import "./ToggleSwitch.scss";

const ToggleSwitch = ({
  id,
  text,
  onChange,
  filterKey,
  image,
  fuelTypeFilterData,
  darkBlue
}) => {
  return (
    <div className="toggle-row">
      <div className="toggle-image">
        <img src={image} alt={filterKey} />
      </div>
      <p className={`toggle-text ${fuelTypeFilterData[filterKey] ? '' : 'toggle-text-disabled'}`}>{text}</p>
      <div
        className={`toggle-switch ${darkBlue ? "toggle-switch-darkBlue" : ""}`}
      >
        <input
          type="checkbox"
          className="toggle-switch-checkbox"
          id={id}
          checked={fuelTypeFilterData[filterKey]}
          onChange={() => onChange(filterKey)}
        />
        {id ? (
          <label className="toggle-switch-label" htmlFor={id}>
            <span className="toggle-switch-inner" />
            <span className="toggle-switch-switch" />
          </label>
        ) : null}
      </div>
    </div>
  );
};

export default ToggleSwitch;

ToggleSwitch.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  onChange: PropTypes.func,
  filterKey: PropTypes.string,
  image: PropTypes.string,
};
