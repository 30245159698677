import React, { useState } from "react";
import PropTypes from "prop-types";
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from "reactstrap";
import Subheader from "../Subheader/Subheader";

import logo from "../../../assets/images/logo.svg";
import "./Header.scss";
import { useIntl } from "react-intl";
import GaTracker from "../../../../utils/GaTracker/GaTracker";
// import { MAP_TABS } from '../../../../constants/mapTabs';

const Header = ({ page }) => {
  const intl = useIntl();
  const [collapse, setCollapse] = useState(false);

  const handleClickWebsite = (e) => {
    GaTracker.trackEvent({
      category: "Header",
      action: "Clicked Header Website Link",
      label: "Link Click",
    });
  };
  // const isHomepage = window.location.pathname === "/" || window.location.pathname === null;

  return (
    <>
      <div className="Header">
        <div className="container">
          <Navbar>
            <div className="logo-container">
              <a
                href="https://newlook.dteenergy.com/wps/wcm/connect/dte-web/home"
                target="_blank"
                rel="noopener noreferrer"
                className="navbar-brand"
              >
                <img
                  src={logo}
                  alt={process.env.REACT_APP_FULL_COMPANY_NAME}
                  className="img-fluid"
                />
              </a>
            </div>
            <NavbarToggler onClick={() => setCollapse(!collapse)} id="hidden" aria-label={collapse ? 'Close navigation' : 'Open navigation'} />
            <Collapse isOpen={collapse} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem className="d-block d-lg-none dte-subheader">
                  <a
                    onClick={(e) => handleClickWebsite(e)}
                    href="https://www.dteenergy.com/us/en/residential/service-request/pev/plug-in-electric-vehicles-pev.html/?utm_campaign=ev_homepage_cta&utm_source=zappy&utm_medium=affiliate_ev_site&utm_content="
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    EV Resources
                  </a>
                </NavItem>
                <NavItem className="d-block d-lg-none">
                  <a
                    onClick={(e) => handleClickWebsite(e)}
                    href="https://www.dteenergy.com/us/en/residential/service-request/pev/home-ev-charger-rebate.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Home EV Charger Rebate
                  </a>
                </NavItem>
                <NavItem
                  onClick={(e) => handleClickWebsite(e)}
                  className="d-block d-lg-none"
                >
                  <a href="/vehicles">EV Showroom</a>
                </NavItem>
                <NavItem
                  onClick={(e) => handleClickWebsite(e)}
                  className="d-block d-lg-none"
                >
                  <a href="/comparison-graph">
                    {intl.formatMessage({
                      id: "vehicle.costVsRange",
                      defaultMessage: "Cost vs. Range",
                    })}
                  </a>
                </NavItem>
                <NavItem
                  onClick={(e) => handleClickWebsite(e)}
                  className="d-block d-lg-none"
                >
                  <a href="/compare-vehicles">Compare Vehicles</a>
                </NavItem>
                <NavItem
                  onClick={(e) => handleClickWebsite(e)}
                  className="d-block d-lg-none"
                >
                  <a href="/charging-stations">Charging Map</a>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        </div>
      </div>
      <Subheader />
    </>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string,
};
