import React, { useContext } from "react";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext"

import IconBEV from "../../assets/images/icons/icon-electric.png";
import IconPHEV from "../../assets/images/icons/icon-hybrid.png";

import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";

import {useIntl} from 'react-intl';

const EVFuelTypeFilter = () => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);

  const fuelTypeFilterData = userPrefs.get("vehicleFuelTypeFilters");

  const updateFuelTypeFilter = selectedKey => {

    let newFilters = Object.assign({}, fuelTypeFilterData);
    newFilters[selectedKey] = !newFilters[selectedKey];

    userPrefs.set({
      vehicleFuelTypeFilters: newFilters
    });
  };

  const renderedFuelTypeFilters = Object.keys(fuelTypeFilterData).map(
    (filterKey, i) => {
      let filterName = "";
      let image
      switch (filterKey) {
        case "bev":
          filterName = intl.formatMessage ? intl.formatMessage({ id: "vehicle.allElectric", defaultMessage: "All Electric"}) : "All Electric";
          image = IconBEV;
          break;
        case "phev":
          filterName = intl.formatMessage ? intl.formatMessage({ id: "vehicle.plugInHybrid", defaultMessage: "Plug in Hybrid"}) : "Plug in Hybrid";
          image = IconPHEV;
          break;
        default:
      }
      return (
        <ToggleSwitch
          darkBlue={filterKey === "phev"}
          key={filterName}
          id={filterName}
          text={filterName}
          filterKey={filterKey}
          onChange={updateFuelTypeFilter}
          image={image}
          fuelTypeFilterData={fuelTypeFilterData}
        />
      );
    }
  );

  
  return (
    <div className="row justify-content-end">{renderedFuelTypeFilters}</div>        
  );
};

export default EVFuelTypeFilter;
