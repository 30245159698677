import USER_PREF_PRESETS_CLIENT_CUSTOM from "../../client_customizations/context/UserPrefs/USER_PREF_PRESETS_CLIENT_CUSTOM"

const USER_PREF_PRESETS = {
  canTurnInClunker: false,
  chargerInstallAllowed: null,
  chargerLevel: "level_2",
  electricMilesPortionForPhev: 60,
  hasOvernightCharger: null,
  gasolinePriceInCentsPerGal: 295,
  hasRegularPowerOutlet: null,
  householdIncome: 40000,
  householdSize: 1,
  includeResaleValue: true,
  interestRateAsBasisPoints: 350,
  locallyAvailableVehicleFilter: false,
  maxBudget: 30000,
  milesDrivenAnnually: 20000,
  milesDrivenDaily: 30,
  minSeats: 2,
  monthsOfOwnership: 60,
  parkingLocation: "",
  purchaseMethod: "cash",
  salesTax: "0.06",
  showAllRates: false,
  showGasolineCost: true,
  showHomeElectricityCost: true,
  showRateDetails: true,
  showDetailsForRate: "EV2-A",
  taxFilingStatus: "single",
  vehicleChargingPattern: "After midnight; Before 3pm",
  vehicleIdForIncentives: "",
  vehicleHandleForIncentives: "",
  vehicleSortDirection: "desc",
  vehicleSortType: "match_score",
  workingZipcode:  `${process.env.REACT_APP_DEFAULTS_POSTAL_CODE}`,
  zipcode: `${process.env.REACT_APP_DEFAULTS_POSTAL_CODE}`,
  chargerBudget: 2000,
  chargerCordLength: 25,
  chargerSortType: "price",
  chargerSortDirection:"asc",
  vehicleMakeFilter: "All",
  vehicleAgeFilter: "All",
  vehicleInsuranceYearly: null,

  vehicleFuelTypeFilters: {
    bev: false,
    phev: false
  },

  vehicleFormFactorFilters: {
    sedan: false,
    hatchback: false,
    coupe: false,
    crossover: false,
    minivan: false,
    suv: false,
    wagon: false,
    truck: false
  },

  chargerWifiFilter: {
    yes: false,
    no: false
  },

  chargerTypeFilters: {
    mounted: false,
    portable: false
  },

  chargerFormFactorFilters: {
    "6-20": false,
    "6-30": false,
    "10-30": false,
    "14-30": false,
    "6-50": false,
    "14-50": false,
    "14-60": false,
    "Hardwired": false 
  }
};

export default { ...USER_PREF_PRESETS, ...USER_PREF_PRESETS_CLIENT_CUSTOM };
