import React, { useContext } from "react";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import SelectVehicleMakeFilter from "../../../components/InputComponents/SelectVehicleMakeFilter/SelectVehicleMakeFilter";

import { FormattedMessage, useIntl } from "react-intl";

const EVFilterControls = ({ vehicles }) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);

  const formFactorFilterData = userPrefs.get("vehicleFormFactorFilters");

  const vehicleTypes = {
    Sedan: intl.formatMessage
      ? intl.formatMessage({
          id: "vehicle.type.sedan",
          defaultMessage: "Sedan",
        })
      : "Sedan",
    Hatchback: intl.formatMessage
      ? intl.formatMessage({
          id: "vehicle.type.hatchback",
          defaultMessage: "Hatchback",
        })
      : "Hatchback",
    Coupe: intl.formatMessage
      ? intl.formatMessage({
          id: "vehicle.type.coupe",
          defaultMessage: "Coupe",
        })
      : "Coupe",
    Crossover: intl.formatMessage
      ? intl.formatMessage({
          id: "vehicle.type.crossover",
          defaultMessage: "Crossover",
        })
      : "Crossover",
    Minivan: intl.formatMessage
      ? intl.formatMessage({
          id: "vehicle.type.minivan",
          defaultMessage: "Minivan",
        })
      : "Minivan",
    SUV: intl.formatMessage
      ? intl.formatMessage({ id: "vehicle.type.suv", defaultMessage: "SUV" })
      : "SUV",
    Wagon: intl.formatMessage
      ? intl.formatMessage({
          id: "vehicle.type.wagon",
          defaultMessage: "Wagon",
        })
      : "Wagon",
    Truck: intl.formatMessage
      ? intl.formatMessage({
          id: "vehicle.type.truck",
          defaultMessage: "Truck",
        })
      : "Truck",
  };

  const updateFormFactorFilter = (key) => {
    let newFilters = Object.assign({}, formFactorFilterData);
    newFilters[key] = !newFilters[key];

    userPrefs.set({
      vehicleFormFactorFilters: newFilters,
    });
  };

  const renderedFormFactorFilters = Object.keys(formFactorFilterData).map(
    (filterKey, i) => {
      const filterName =
        filterKey === "suv"
          ? "SUV"
          : `${filterKey.charAt(0).toUpperCase()}${filterKey.substr(1)}`;
      return (
        <button
          className={formFactorFilterData[filterKey] ? "btn" : "btn active"}
          key={i}
          type="button"
          aria-pressed={!!formFactorFilterData[filterKey]}
          onClick={() => updateFormFactorFilter(filterKey)}
        >
          {vehicleTypes[filterName]}
        </button>
      );
    }
  );

  return (
    <div className="row col-12 input-row">
      <div className="form-group col-10">
        <label className="label-style">
          <FormattedMessage
            id="evfilter.type"
            defaultMessage="Type"
            description="Type"
          />
        </label>
        <div className="btn-grid-container">{renderedFormFactorFilters}</div>
      </div>

      <div className="form-group col-2">
        <SelectVehicleMakeFilter vehicles={vehicles} />
      </div>
    </div>
  );
};

export default EVFilterControls;
